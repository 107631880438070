import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import PostTemplate from "./PostTemplate"

const CaseStudy = ({ data: { caseStudy, backgroundImage } }) => {
  return (
    <StyledCaseStudy>
      <PostTemplate
        post={caseStudy}
        backgroundImage={backgroundImage}
        slugBase="/case-study"
      />
    </StyledCaseStudy>
  )
}

const StyledCaseStudy = styled.div``

export default CaseStudy

export const query = graphql`
  query CaseStudySlug($slug: String!) {
    caseStudy: contentfulCaseStudy(slug: { eq: $slug }) {
      ...CaseStudy
    }
    backgroundImage: contentfulMedia(mediaId: { eq: "dot_matrix" }) {
      ...Media
    }
  }
`
